<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items="calcPvcs"
          :headers="headers"
          class="elevation-1"
          :items-per-page="itemsPerPage"
          hide-default-footer
        >
          <template v-slot:[`item.url`]="{ value }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on"
                  rounded
                  color="primary"
                  :href="value" target="_blank">
                  Open in Portal <v-icon>{{icons.mdiOpenInNew}}</v-icon>
                </v-btn>
              </template>
              <span>{{value}}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import gql from 'graphql-tag';
import {
  mdiOpenInNew,
} from '@mdi/js';
import { clients } from '../../util/clients';
import { TMC_CONSTANTS } from '../../util/shared/constants';

const { backend } = clients.direct;

export default {
  name: 'K8sPvcGrid',
  components: {
  },
  data: () => ({
    icons: {
      mdiOpenInNew,
    },
    pvcs: [],
    headers: [
      { text: 'Name', value: 'name' },
      { text: 'Namespace', value: 'namespace' },
      { text: 'Link', value: 'url' },
    ],
    itemsPerPage: 50, // Just a rough limit
  }),
  created() {
    this.loadPvcs();
  },
  computed: {
    calcPvcs() {
      const { SUBSCRIPTION_ID, STORAGE_ACCT_ID } = TMC_CONSTANTS;
      const rawCountyName = (namespace) => (namespace || '').replace('tmc-local-', '');
      const countyUploadShareName = (namespace) => `tmclocstor${rawCountyName(namespace)}`;
      const uploadShareUrl = (namespace) => `https://portal.azure.com/#view/Microsoft_Azure_FileStorage/FileShareMenuBlade/~/overview/storageAccountId/%2Fsubscriptions%2F${SUBSCRIPTION_ID}%2FresourceGroups%2Ftmc-local%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2F${countyUploadShareName(namespace)}/path/uploadstg/protocol/SMB`;
      const dynamicVolumeUrl = (uid) => ` https://portal.azure.com/#view/Microsoft_Azure_FileStorage/FileShareMenuBlade/~/overview/storageAccountId/%2Fsubscriptions%2F${SUBSCRIPTION_ID}%2FresourceGroups%2Fmc_tmc-local_tmc-local-blue_southcentralus%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2F${STORAGE_ACCT_ID}/path/kubernetes-dynamic-pvc-${uid}/protocol/SMB`;
      const deprecatedJobDataUrl = (uid) => `https://portal.azure.com/#@jdwestropegmail.onmicrosoft.com/resource/subscriptions/${SUBSCRIPTION_ID}/resourceGroups/mc_tmc-local_tmc-local-blue_southcentralus/providers/Microsoft.Compute/disks/kubernetes-dynamic-pvc-${uid}/overview`;
      return this.pvcs.map((pvc) => {
        let url = '';
        let { name } = pvc;
        if (name === 'tmc-local-jobs-nightly-data') {
          url = deprecatedJobDataUrl(pvc.uid);
          name = `(deprecated) ${name}`;
        } else if (name === 'pvc-azure-file-share-upload-stg') {
          url = uploadShareUrl(pvc.namespace);
        } else {
          url = dynamicVolumeUrl(pvc.uid);
        }
        return {
          ...pvc,
          name,
          url,
        };
      });
    },
  },
  methods: {
    async loadPvcs() {
      let results;
      try {
        results = await backend.query({
          query: gql`{
                    k8spvcs { name, namespace, uid }
                  }`,
        });
        clients.handleGQLErrors(results, this.$store.commit);
        if (results.data && results.data.k8spvcs) {
          const { k8spvcs } = results.data;
          this.pvcs = k8spvcs;
        }
      } catch (e) {
        this.$store.dispatch('logException', e);
      }
    },
  },
};

</script>
