<template>
  <v-row>
    <v-col
      cols="12"
      md="2"
    >
      <v-list
        nav
        dense
      >
        <ProgramList />
      </v-list>
    </v-col>
    <v-col
      cols="12"
      md="10"
    >
      <v-tabs>
        <v-tab>Volumes</v-tab>
        <v-tab-item>
          <K8sPvcGrid />
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>

<script>
import ProgramList from '@/components/common/ProgramList.vue';
import K8sPvcGrid from '@/components/SystemInfo/K8sPvcGrid.vue';

export default {
  components: {
    ProgramList,
    K8sPvcGrid,
  },
};
</script>
